import { AxiosError } from 'axios';
import { dbAxiosInstance, functionsAxiosInstance } from '../../frameworks';
import { MailProps, PrizeStatus, TemplateMailProps } from '../../core';

export const getPrizes = async (prizeStatus: PrizeStatus) => {
  try {
    return await dbAxiosInstance
      .get(`prizes?status=eq.${prizeStatus}&select=*`)
      .then((res) => res.data);
  } catch (error) {
    return (error as AxiosError).response;
  }
};

export const claimPrize = async (
  ticketId: string,
  email: string,
  prizeId: string
) => {
  try {
    return await functionsAxiosInstance.post(
      `claim-prize`,
      {
        ticketId,
        email,
        prizeId,
      },
      {
        headers: {
          'Content-Type': 'application/json',
        },
      }
    );
  } catch (error) {
    return (error as AxiosError).response;
  }
};

export const sendClaimPrizeEmail = async (
  data: MailProps | TemplateMailProps
) => {
  try {
    return await functionsAxiosInstance.post('send-email', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    return error as AxiosError;
  }
};
