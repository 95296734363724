import { Link } from 'react-router-dom';

import './landing.scss';
import Logo from '../../assets/images/logo.png';
import { useLanding } from './landing.hook';

function Landing() {
  const { handleNoButtonClick, showMessage } = useLanding();

  return (
    <>
      <div className='landing-container'>
        <div className='landing'>
          <img className='landing__logo' src={Logo} alt='' />
          {showMessage ? (
            <h2 className='landing__title'>
              SORRY, YOU MUST BE 21 OR OLDER TO ENTER THIS SITE.
            </h2>
          ) : (
            <h2 className='landing__title'>Are you 21 or older?</h2>
          )}
          {!showMessage && (
            <div className='landing__buttons'>
              <Link to={`/enter-social`}>
                <button className='landing__buttons__button accept'>Yes</button>
              </Link>
              <button
                className='landing__buttons__button decline'
                onClick={handleNoButtonClick}
              >
                No
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Landing;
