import React from 'react';
import { FormDropdownProps } from './form-dropdown.model';
import { Controller, FieldValues, Path } from 'react-hook-form';
import { FormError } from '../form-error';

export function FormDropDown<T extends FieldValues>({
  selectClassName,
  optionClassName,
  defaultOptionClassName,
  control,
  defaultValue,
  errorPosition = 'top',
  errors,
  name,
  options,
  showError = true,
}: FormDropdownProps<T>) {
  return (
    <>
      {errorPosition === 'top' && showError && (
        <FormError errors={errors} name={name as string} />
      )}
      <Controller
        name={name as Path<T>}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <select onChange={onChange} className={selectClassName}>
            {defaultValue && (
              <option
                disabled
                selected
                value={defaultValue.value}
                className={defaultOptionClassName}
              >
                {defaultValue.label}
              </option>
            )}
            {options?.map((option) => (
              <option
                key={option.label}
                value={option.value}
                className={optionClassName}
              >
                {option.label}
              </option>
            ))}
          </select>
        )}
      />
      {errorPosition === 'bottom' && showError && (
        <FormError errors={errors} name={name as string} />
      )}
    </>
  );
}
