import axios from 'axios';

const DB_BASE_URL = process.env.REACT_APP_DB_BASE_URL;
const DB_BEARER_TOKEN = process.env.REACT_APP_DB_BEARER_TOKEN;
const DB_API_KEY = process.env.REACT_APP_DB_API_KEY;
const FUNCTION_BASE_URL = process.env.REACT_APP_FUNCTIONS_BASE_URL;

export const dbAxiosInstance = axios.create({
  baseURL: DB_BASE_URL,
  headers: { Authorization: DB_BEARER_TOKEN, apikey: DB_API_KEY },
});

export const functionsAxiosInstance = axios.create({
  baseURL: FUNCTION_BASE_URL,
  headers: { Authorization: DB_BEARER_TOKEN, apikey: DB_API_KEY },
});
