import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';

import { useFormValidations, useTicket } from '../../hooks';
import { UseSelectPrize } from './claim-prize.model';
import { ClaimPrizeSchema, ClaimPrizeSchemaType } from './claim-prize.schema';
import {
  claimPrize,
  getPrizes,
  sendClaimPrizeEmail,
} from './claim-prize.service';
import { mapPrizes } from './claim-prize.adapter';
import { EmailSubjectContent, OptionType, TemplateMailProps } from '../../core';
import { useNavigate, useParams } from 'react-router-dom';

const TEMPLATE_ID = process.env.REACT_APP_SEND_GRID_TEMPLATE_ID;

const JACKPOT_URL = 'https://jackpot.buzz';

export const useSelectPrize = (): UseSelectPrize => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { id } = useParams();
  const [ticketId, setTicketId] = useState<string>(id ?? '');
  const [prizes, setPrizes] = useState<OptionType[]>();
  const navigate = useNavigate();

  const handleGetPrizes = async () => {
    const prizesRes = await getPrizes('active');
    if (prizesRes?.status === 400) return;

    setPrizes(mapPrizes(prizesRes));
  };

  const handleSubmit = async (data: ClaimPrizeSchemaType) => {
    setIsSubmitting(true);
    const claimPrizeResult = await claimPrize(
      ticketId,
      data.email,
      data.prizeId
    );

    if (claimPrizeResult?.status === 400) {
      toast.error(claimPrizeResult?.data, { position: 'top-center' });
      setIsSubmitting(false);
      return;
    }

    const content: TemplateMailProps = {
      to: data.email,
      templateId: TEMPLATE_ID!,
      dynamicTemplateData: {
        subject: EmailSubjectContent.PRIZE_CLAIMED.subject,
        name: data.email.toUpperCase().split('@')[0],
        message: `${EmailSubjectContent.PRIZE_CLAIMED.message1}`,
        url: JACKPOT_URL,
        urlLabel: `We would like to invite you to share your positive experience on social “LINK HERE”`,
      },
    };
    await sendClaimPrizeEmail(content);

    setIsSubmitting(false);
    navigate('/prize-claimed');
  };

  const {
    errors: formErrors,
    control,
    submit,
  } = useFormValidations<ClaimPrizeSchemaType>({
    schema: ClaimPrizeSchema,
    onSubmit: async (data: ClaimPrizeSchemaType) => {
      handleSubmit(data);
    },
  });

  useEffect(() => {
    !prizes && handleGetPrizes();
  }, [prizes]);

  return {
    control,
    formErrors,
    isSubmitting,
    prizes,
    submit,
  };
};
