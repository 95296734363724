import { useNavigate } from 'react-router-dom';
import { useTicket } from '../../hooks';
import uuid from 'react-uuid';

export const useEnterSocial = () => {
  const navigate = useNavigate();
  const { getTicketId, setTicketId, setIsAutoGenerated } = useTicket();

  const setTicketIdFromLink = () => {
    const ticket = getTicketId() !== '' ? `${getTicketId()}` : `${uuid()}`;

    setTicketId(ticket);
    setIsAutoGenerated(true);
    navigate(`/sweepstakes`);
  };

  return { ticketId: getTicketId(), setTicketIdFromLink };
};
