import { AxiosError } from 'axios';
import { dbAxiosInstance, functionsAxiosInstance } from '../../frameworks';
import { SweepstakesSchemaType } from './sweepstakes.schema';
import { MailProps, TemplateMailProps } from '../../core';

export const saveSubmission = async (data: SweepstakesSchemaType) => {
  try {
    return await dbAxiosInstance.post('submissions', data);
  } catch (error) {
    return error as AxiosError;
  }
};

export const getSubmission = async (ticketId: string) => {
  try {
    return await dbAxiosInstance
      .get(`submissions?ticketId=eq.${ticketId}&select=*`)
      .then((res) => res.data[0]);
  } catch (error) {
    return error as AxiosError;
  }
};

export const sendSubmissionEmail = async (
  data: MailProps | TemplateMailProps
) => {
  try {
    return await functionsAxiosInstance.post('send-email', data, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  } catch (error) {
    return error as AxiosError;
  }
};

export const getEventDate = async () =>{
  try{
    const response = await functionsAxiosInstance('event-date')
    return response.data;
  }catch(error){
    return error as AxiosError
  }
}
