import { Controller, FieldValues, Path } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { MaskedFormInputProps } from './masked-form-input.model';
import { FormError } from '../form-error';

export function MaskedFormInput<T extends FieldValues>({
  className,
  control,
  errors,
  errorPosition = 'top',
  name,
  placeholder,
  showError = true,
  type = 'text',
  mask,
  maskChar = '_',
}: MaskedFormInputProps<T>) {
  return (
    <>
      {errorPosition === 'top' && showError && (
        <FormError errors={errors} name={name as string} />
      )}
      <Controller
        name={name as Path<T>}
        control={control}
        render={({ field: { onChange, onBlur, value } }) => (
          <InputMask
            mask={mask}
            value={value}
            onChange={onChange}
            className={className}
            placeholder={placeholder}
            maskChar={maskChar}
          />
        )}
      />
      {errorPosition === 'bottom' && showError && (
        <FormError errors={errors} name={name as string} />
      )}
    </>
  );
}
