import './Footer.scss';
import LogoFooter from '../../assets/images/logo-footer.png';
import ig from '../../assets/images/ig.png';
import { Link } from 'react-router-dom';
import { YEAR_TO_SHOW } from '../../constants';

function Footer() {
  return (
    <div className='footer-container'>
      <div className='footer-container-wrap'>
        <div className='footer-container-wrap-left'>
          <Link to={`/`}>
            <img className='footer-container-wrap-logo' src={LogoFooter} alt='' />
          </Link>
          <div className='footer-container-wrap-copyright-left'>
            <p className='footer-container-wrap-routes-text'>{`©${YEAR_TO_SHOW} ALL RIGHTS RESERVED`}</p>
          </div>
          <div className='footer-container-wrap-routes'>
            <Link to='/official-rules'>
              <p className='footer-container-wrap-routes-text'>CONTEST RULES</p>
            </Link>
            <Link to='/private-policy'>
              <p className='footer-container-wrap-routes-text'>PRIVACY POLICY</p>
            </Link>
          </div>
        </div>
        <div className='footer-container-wrap-right'>
          <div className='footer-container-wrap-copyright'>
            <p className='footer-container-wrap-routes-text'>{`©${YEAR_TO_SHOW} ALL RIGHTS RESERVED`}</p>
          </div>
          <div className='footer-container-wrap-media'>
            <a
              href='https://www.instagram.com/jackpot.buzz?igsh=MTU4eDJ6NjE2azFhbQ%3D%3D'
              target='_blank'
              rel='noreferrer'
            >
              <img className='footer-container-wrap-media-logo' src={ig} alt='' />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
