import { ReactNode } from 'react';

export const Rules = (): ReactNode => {
  return (
    <p style={{ lineHeight: 1.7 }}>
      <b>Eligibility</b> <br />
      1.1 The sweepstakes is open to individuals who are 21 years of age or older at the time of entry. Void where
      prohibited by law. <br />
      1.2 Employees, officers, directors, and immediate family members of TJIM Industries and its affiliates, as well as
      their respective advertising and promotion agencies, are not eligible to participate in the sweepstakes. <br />
      <br />
      <b>Entry</b> <br />
      2.1 No purchase or payment of any kind is necessary to enter or win the sweepstakes. <br />
      2.2 To enter, participants must provide the required personal information through the entry form on this official
      sweepstakes page <a href='//www.jackpot.buzz/entry'>www.jackpot.buzz/entry</a> or as otherwise specified. Multiple
      entries per person are not permitted. All entries must be received the day previous to the drawing. <br />
      2.3 Only one entry per entry code is permitted. Multiple entries from the same person will be disqualified. <br />
      <br />
      <b>Prize</b> <br />
      3.1 The prize for the sweepstakes will be described on the official sweepstakes page or in the promotional
      materials. The prize is non-transferable, non-exchangeable, and cannot be substituted for cash or any other prize.{' '}
      <br />
      3.2 The odds of winning the prize depend on the total number of eligible entries received.
      <br />
      3.3 Winners will be selected at random from all eligible entries. The winner will be notified by email or phone
      within a reasonable time after the drawing date. If a winner does not respond within 30 days of the notification,
      an alternate winner may be selected.
      <br />
      <br />
      <b>Publicity</b> <br />
      4.1 By participating in the sweepstakes, winners agree to allow TJIM Industries to use their name, likeness, and
      entry for promotional and marketing purposes without additional compensation, unless prohibited by law.
      <br />
      <br />
      <b>Privacy</b> <br />
      5.1 Any personal information provided by participants will be used in accordance with our Privacy Policy. By
      entering the sweepstakes, participants consent to the collection, use, and disclosure of their personal
      information for the purposes of administering the sweepstakes and fulfilling any prizes.
      <br />
      <br />
      <b>General Conditions</b> <br />
      6.1 TJIM Industries reserves the right to modify, suspend, or terminate the sweepstakes at any time, for any
      reason, without notice or liability.
      <br />
      6.2 TJIM Industries is not responsible for late, lost, incomplete, or misdirected entries or for any technical or
      human errors that may occur during the entry process or the administration of the sweepstakes.
      <br />
      6.3 Participants agree to release and hold harmless TJIM Industries, its affiliates, and their respective
      directors, officers, employees, and agents from any and all liability or any injury, loss, or damage of any kind
      arising from or in connection with their participation in the sweepstakes or acceptance or use of the prize.
      <br />
      <br />
      <b>Governing Law and Jurisdiction</b> <br />
      7.1 The sweepstakes and these rules are governed by the laws of Massachusetts. Any disputes arising out of or in
      connection with the sweepstakes shall be subject to the exclusive jurisdiction of the courts of Massachusetts.
      <br />
      <br />
    </p>
  );
};
