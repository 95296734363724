import { Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import './App.scss';
import { ClaimPrizePage } from './pages/claim-prize';
import EnterSocial from './pages/enter/enter-social';
import { EntryReceived } from './pages/entry-received';
import Footer from './components/footer/Footer';
import Landing from './pages/landing/landing';
import RulesAndPolicy from './pages/official-rules/rules-policy';
import Sweepstakes from './pages/sweepstakes/sweepstakes';
import { ClaimedPrize } from './pages/prize-claimed/prize-claimed.page';
import { Policy, Rules } from './components';

function App() {
  const location = useLocation();
  return (
    <main className={`main-wrapper ${location.pathname === '/sweepstakes' ? '' : 'content-footer'} `}>
      <div className='content'>
        <Routes>
          <Route path='/' element={<Landing />} />
          <Route path='/:id' element={<Landing />} />
          <Route path='/enter-social' element={<EnterSocial />} />
          <Route path='/sweepstakes' element={<Sweepstakes />} />
          <Route path='/entry-received' element={<EntryReceived />} />
          <Route path='/prize-claimed' element={<ClaimedPrize />} />
          <Route path='/official-rules' element={<RulesAndPolicy title='CONTEST RULES' content={Rules()} />} />
          <Route path='/private-policy' element={<RulesAndPolicy title='PRIVACY POLICY' content={Policy()} />} />
          <Route path='/claim-prize/:id' element={<ClaimPrizePage />} />
        </Routes>
      </div>
      <Footer />
      <ToastContainer />
    </main>
  );
}

export default App;
