import Logo from '../../assets/images/big-logo.png';
import youtube from '../../assets/images/youtube.png';
import ig from '../../assets/images/ig.png';
import facebook from '../../assets/images/facebook.png';
import calendar from '../../assets/images/calendar.png';
import { MessageProps } from './message.models';
import { useEventDate } from '../../context/eventDateContext';
export const MessageComponent = ({ message }: MessageProps) => {
const { date } = useEventDate(); 
  return (
    <>
      <div className='entry-receive'>
        <div className='entry-receive__container'>
          <img className='entry-receive__container__logo' src={Logo} alt='' />
          <div className='entry-receive__container__body'>
            <p className='entry-receive__container__body__title'>{message}</p>
            <p className='entry-receive__container__body__subtitle'>
              FOLLOW US FOR MORE WAYS TO WIN!
            </p>
            <div className='entry-receive__container__body__date-container'>
              <img
                className='entry-receive__container__body__date-container-logo'
                src={calendar}
                alt=''
              />
              <p className='entry-receive__container__body__date-container-text'>
                {date}
              </p>
            </div>

            <div className='entry-receive__container__body__media'>
              <a
                href='https://www.youtube.com/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='entry-receive__container__body__media-logo'
                  src={youtube}
                  alt=''
                />
              </a>
              <a
                href='https://www.instagram.com/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='entry-receive__container__body__media-logo'
                  src={ig}
                  alt=''
                />
              </a>
              <a
                href='https://www.facebook.com/'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  className='entry-receive__container__body__media-logo'
                  src={facebook}
                  alt=''
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
