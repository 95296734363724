import { CookiesAlertHook, useCookiesAlert } from '../../hooks';
import './cookies-alert.scss';

function CookiesAlert() {
  const {
    showAlert,
    handleAcceptCookies,
    handleDeclineCookies,
  }: CookiesAlertHook = useCookiesAlert();

  return (
    <>
      {showAlert && (
        <div className='cookies-alert'>
          <p className='cookies-alert__text'>
            This website uses cookies to ensure that you get the best experience
            on our website. Accept cookies?
          </p>
          <div className='cookies-alert__buttons'>
            <button
              className='cookies-alert__buttons__button accept'
              onClick={handleAcceptCookies}
            >
              Accept
            </button>
            <button
              className='cookies-alert__buttons__button decline'
              onClick={handleDeclineCookies}
            >
              Decline
            </button>
          </div>
        </div>
      )}
    </>
  );
}

export default CookiesAlert;
