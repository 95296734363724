import { ErrorMessage } from '@hookform/error-message';

import { FormFieldErrorProps } from './form-error.model';
import { errorMessageStyles } from './form-error.styles';

export function FormError<T>({ errors, name }: FormFieldErrorProps<T>) {
  return (
    <ErrorMessage
      errors={errors}
      name={name as string}
      render={({ message }) => <p style={errorMessageStyles}>{message}</p>}
    />
  );
}
