import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

export const useTicket = () => {
  const ticketIdStorageVarName = 'ticketId';
  const isAutoGeneratedStorageVarName = 'isAutoGenerated';
  const { id } = useParams();

  const getTicketId = (): string => {
    const ticketId = localStorage.getItem(ticketIdStorageVarName);
    return ticketId ?? '';
  };

  const getIsAutoGenerated = () => {
    const isAutoGenerated = localStorage.getItem(isAutoGeneratedStorageVarName);
    return isAutoGenerated !== null
      ? isAutoGenerated.toLowerCase() === 'true'
      : false;
  };

  const setTicketId = (ticketId: string) => {
    localStorage.setItem(ticketIdStorageVarName, ticketId);
  };

  const setIsAutoGenerated = (isAutoGenerated: boolean) => {
    localStorage.setItem(
      isAutoGeneratedStorageVarName,
      isAutoGenerated.toString()
    );
  };

  useEffect(() => {
    id && localStorage.setItem(ticketIdStorageVarName, id);
  }, [id]);

  return {
    getTicketId,
    setTicketId,
    getIsAutoGenerated,
    setIsAutoGenerated,
  };
};
