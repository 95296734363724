import Logo from '../../assets/images/big-logo.png';
import { FormInput } from '../../components';
import { FormDropDown } from '../../components/atoms/form-dropdown';
import { Loader } from '../../components/atoms/loader';
import { useSelectPrize } from './claim-prize.hook';
import { ClaimPrizeSchemaType } from './claim-prize.schema';
import './claim-prize.scss';

export const ClaimPrizeComponent = () => {
  const { control, formErrors, isSubmitting, prizes, submit } =
    useSelectPrize();

  return (
    <div className='claim-prize'>
      <div className='claim-prize__container'>
        <div>
          <img className='claim-prize__container__logo' src={Logo} alt='' />
        </div>
        <form className='claim-prize__container__body' onSubmit={submit}>
          <FormInput<ClaimPrizeSchemaType>
            className='claim-prize__container__input'
            control={control}
            errors={formErrors}
            name='email'
            placeholder='Enter your email'
          />
          <FormDropDown<ClaimPrizeSchemaType>
            selectClassName='claim-prize__container__dropdown'
            optionClassName='claim-prize__container__dropdown__option'
            control={control}
            defaultValue={{ label: 'Select a prize', value: '' }}
            errors={formErrors}
            name='prizeId'
            options={prizes}
          />
          <div>
            {isSubmitting ? (
              <Loader />
            ) : (
              <button className='claim-prize__container__button'>SUBMIT</button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};
