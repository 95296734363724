import { useEffect, useState } from "react";

export interface CookiesAlertHook {
  showAlert: boolean;
  handleAcceptCookies: () => void;
  handleDeclineCookies: () => void;
}

export function useCookiesAlert(): CookiesAlertHook {
    const [showAlert, setShowAlert] = useState(true);
  
    useEffect(() => {
      const hasAcceptedCookies = localStorage.getItem('acceptedCookies') === 'true';
      setShowAlert(!hasAcceptedCookies);
    }, []);
  
    const handleAcceptCookies = () => {
      localStorage.setItem('acceptedCookies', 'true');
      setShowAlert(false);
    };
  
    const handleDeclineCookies = () => {
      localStorage.setItem('acceptedCookies', 'false');
      setShowAlert(false);
    };
  
    return {
      showAlert,
      handleAcceptCookies,
      handleDeclineCookies,
    };
  }