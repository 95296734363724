import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { UseLanding } from './landing.model';
import { useTicket } from '../../hooks';

export const useLanding = (): UseLanding => {
  const params = useParams();
  const [showMessage, setShowMessage] = useState(false);
  const { id } = params;
  const { setTicketId } = useTicket();

  const handleNoButtonClick = () => {
    setShowMessage(true);
  };

  useEffect(() => {
    id && setTicketId(id);
  }, [setTicketId, id]);

  return {
    showMessage,
    handleNoButtonClick,
  };
};
