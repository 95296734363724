import { InferType, boolean, object, string } from 'yup';
import { EMAIL_REGEX, PHONE_REGEX, ZIP_CODE_REGEX } from '../../constants';

export const SweepstakesSchema = object({
  email: string()
    .matches(EMAIL_REGEX, 'Email is not valid')
    .required('Email is required'),
  firstName: string().required('Name is required'),
  acceptedOfficialRules: boolean()
    .required('You must agree to the official rules')
    .oneOf([true], 'You must agree to the official rules'),
  phone: string()
    .required('Phone is required')
    .matches(PHONE_REGEX, 'Phone is not valid'),
  receiveEmails: boolean(),
  zipCode: string()
    .required('Zip Code is required')
    .matches(ZIP_CODE_REGEX, 'Zip Code is not valid'),
  isAutoGenerated: boolean(),
  ticketId: string().test(
    'isAutoGenerated',
    'Must have a Ticket Id to submit the form',
    function () {
      if (this.parent.isAutoGenerated) {
        return this.parent.isAutoGenerated;
      } else {
        return !this.parent.isAutoGenerated && this.parent.ticketId !== '';
      }
    }
  ),
});

export type SweepstakesSchemaType = InferType<typeof SweepstakesSchema>;
