import { OptionType } from '../../core';
import { Prize } from './claim-prize.model';

export const mapPrizes = (prizes: Prize[]): OptionType[] => {
  if (!prizes || prizes.length < 1) return [];

  return prizes.reduce((acc: OptionType[], prize) => {
    prize.name &&
      acc.push({
        label: prize.name,
        value: `${prize.id}`,
      });
    return acc;
  }, []);
};
