import './rules-policy.scss';
import arrowBack from '../../assets/images/arrow-back.png';
import { useNavigate } from 'react-router-dom';
import { ReactNode } from 'react';

interface OfficialRulesProps {
  title: string;
  content: ReactNode;
}

function RulesAndPolicy({ title, content }: Readonly<OfficialRulesProps>) {
  const navigate = useNavigate();

  const goBack = () => navigate(-1);

  return (
    <div className='rules'>
      <div className='rules-container'>
        <div className='rules-container-header'>
          <button onClick={goBack} style={{ background: 'transparent', border: 'none' }}>
            <img src={arrowBack} alt='go back' />
          </button>
          <div className='rules-container-title'>
            <p> {title}</p>
          </div>
        </div>
        {content}
      </div>
    </div>
  );
}

export default RulesAndPolicy;
