import React, { createContext, useContext, useState, useEffect } from 'react';
import { getEventDate as fetchEventDate } from '../pages/sweepstakes/sweepstakes.service';
import moment from 'moment-timezone';

// Define the context type
interface EventDateContextType {
  date: string;
}

// Create the context
const EventDateContext = createContext<EventDateContextType | undefined>(undefined);

// Provider component
export const EventDateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [date, setDate] = useState('');

  const transformDate = (fullDate: string) => {
    const date = moment(fullDate);
    return date?.format('[DRAWING ON:] M/D, hA');
  };

  useEffect(() => {
    const fetchDate = async () => {
      try {
        const data = await fetchEventDate();
        const newDate = transformDate(data?.event_date);
        setDate(newDate);
      } catch (error) {
        console.error('Error fetching event date:', error);
      }
    };

    fetchDate();
  }, []);

  return (
    <EventDateContext.Provider value={{ date }}>
      {children}
    </EventDateContext.Provider>
  );
};

// Custom hook to use the context
export const useEventDate = (): EventDateContextType => {
  const context = useContext(EventDateContext);
  if (context === undefined) {
    throw new Error('useEventDate must be used within an EventDateProvider');
  }
  return context;
};
