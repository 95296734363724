import './enter-social.scss';
import Logo from '../../assets/images/big-logo.png';
import CookiesAlert from '../../components/cookies-alert/cookies-alert';
import { Link } from 'react-router-dom';
import { useEnterSocial } from './enter-social.hook';

function EnterSocial() {
  const { ticketId, setTicketIdFromLink } = useEnterSocial();
  return (
    <>
      <div className='enter'>
        <div className='enter__container'>
          <Link to={`/${ticketId}`}>
            <img className='enter__container__logo' src={Logo} alt='' />
          </Link>
          {ticketId !== '' ? (
            <Link to='/sweepstakes'>
              <button className='enter__container__button'>
                Enter the sweepstakes
              </button>
            </Link>
          ) : (
            <button
              onClick={setTicketIdFromLink}
              className='enter__container__button'
              style={{ padding: 10, height: 'auto' }}
            >
              Don't you have a code? Click here to continue
            </button>
          )}
        </div>
      </div>
      <CookiesAlert></CookiesAlert>
    </>
  );
}

export default EnterSocial;
